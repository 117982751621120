import React, { useEffect, useState } from 'react';
import Layout from '../../common/Layout';
import { Helmet } from 'react-helmet';
import SearchResultHero from './SearchResult/SearchResultHero';
import SearchResultFeatured from './SearchResult/SearchResultFeatured';
import { useSiteInfo } from '../../crafter_site_core/hooks';
import CircularProgressSpinner from '../../crafter_site_core/CircularProgressSpinner';
import SearchResultSection from './SearchResult/SearchResultSection';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import SearchBar from '../web_components/SearchBar';
import { getQueryFromCurated } from '../../common/SearchQueryInfo';
import { useGlobalContext } from '../../crafter_site_core/context';
import {createQuery, search} from "@craftercms/search";
import {parseDescriptor, preParseSearchResults} from "@craftercms/content";

export default function () {
  const siteinfo = useSiteInfo();
  const [, updateQueryDetails] = useGlobalContext();
  const navurl = window.location.pathname;
  const [curatedSearchInfo, setCuratedSearchInfo] = useState(null);

  useEffect(() => {
    // console.log("CuratedSearchResult querying elastic for url: "+navurl);
      const query = createQuery('elasticsearch');
      query.query = {
        query: {
          bool: {
            must: [
              {
                term: {
                  url_s: navurl,
                },
              },
            ],
          },
        },
        from: 0,
        size: 1,
      };
      search(query).subscribe((res) => {
        // console.log("useCuratedSearch elastic query ran: " + JSON.stringify(query, null, 2) + " from Elastic: " + JSON.stringify(res, null, 2))
        if (res.total.value.value === 0) {
          // console.log("Curated search did not find a result.  navurl:"+navurl)
          setCuratedSearchInfo(null); // todo: 404 please....
        } else if (res.total.value.value >= 1) {
          const obj = parseDescriptor(
            preParseSearchResults(res.hits[0]._source)
          );
          setCuratedSearchInfo(obj);
        }
      });

  }, [navurl]);


  // go get the item
  useEffect(() => {
    if (curatedSearchInfo) {
      updateQueryDetails(getQueryFromCurated(curatedSearchInfo));
    }
  }, [curatedSearchInfo, updateQueryDetails]);

  if (curatedSearchInfo) {
    const hero = curatedSearchInfo.hero_o ? curatedSearchInfo.hero_o[0] : null;

    return (
      <Layout>
        <Helmet>
          <meta charSet='utf-8' />
          <title>
            {curatedSearchInfo.title_s + ' | ' + siteinfo?.siteName_s}
          </title>
        </Helmet>
        <SearchBar />
        {hero ? <SearchResultHero model={hero} /> : <></>}
        <Box my={4}>
          <Container maxWidth='lg'>
            <Grid container spacing={3}>
              {curatedSearchInfo?.featuredItems_o?.map((featured, index) => (
                <Grid item xs>
                  <SearchResultFeatured
                    key={index}
                    model={featured}
                    count={curatedSearchInfo?.featuredItems_o?.length}
                  />
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
        <SearchResultSection />
      </Layout>
    );
  } else {
    return <CircularProgressSpinner message={'Loading content...'} technical={'Curated Search Results'} />;
  }
}
